import { Button, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useCSVReader } from 'react-papaparse';
import { getAllBienLeadsPhones } from './api/axios';
import Header from './component/header';
import ConfirmNotification from './component/ux/confirmation';
import Toast from './component/ux/toast';
import AuthContext from './context/AuthProvider';
import { getPhoneNumberUniqueIdentifierPart } from './lib/tools/strings';


export default function Compta() {
    const { auth, listsIds, color, secondaryColor } = useContext(AuthContext);

    const [toast, setToast] = useState(null);
    const [confirmation, setConfirmation] = useState(null);

    const { CSVReader } = useCSVReader();
    const [isCSVProcessed, setCSVProcessed] = useState(false);
    const [csvContent, setCSVContent] = useState(null);

    const [csvColumns, setCSVColumns] = useState(null);
    const [phoneColumn, setPhoneColumn] = useState("__undefined__");
    const [revenueColumn, setRevenueColumn] = useState("__undefined__");

    const [leadsPhones, setLeadPhones] = useState(null);
    const [laSales, setLASales] = useState(null);

    useEffect(() => {
        getAllBienLeadsPhones()
            .then(data => {
                setLeadPhones(data.data);
            });
    }, []);

    function getNumericRevenue(str) {
        const regex = /[0-9]([0-9 ]*[0-9])?/;
        const match = str.match(regex);

        if (match) {
            return parseFloat(match[0]);
        } else {
            return 0;
        }
    }

    useEffect(() => {
        if (phoneColumn === "__undefined__" || revenueColumn === "__undefined__" || leadsPhones === null
            || csvContent === null
        ) {
            return;
        }

        const phonesCleaned = leadsPhones.map(pho => getPhoneNumberUniqueIdentifierPart(pho));
        let setPhones = new Set(phonesCleaned);
        console.log('phonesCleaned.slice(0,10) :>> ', phonesCleaned.slice(0, 10));
        console.log('phonesCleaned.slice(1000,10) :>> ', phonesCleaned.slice(1000, 10));

        const laSales = [["Téléphone", "Revenus non formatéss", "Revenus formatés"]];
        let sumRevenue = 0.;
        for (let i = 0; i < csvContent.length; i++) {
            let row = csvContent[i];
            if (row.length < 2) {
                continue;
            }

            let rawRevenue = row[revenueColumn];
            let rawPhone = row[phoneColumn];
            let phone = getPhoneNumberUniqueIdentifierPart(rawPhone);

            if (phone.length < 8) {
                continue;
            }

            if (i < 20) {
                console.log('phone :>> ', phone, 'rawPhone :>> ', rawPhone);
            }

            if (setPhones.has(phone)) {
                const revenue = getNumericRevenue(rawRevenue);
                laSales.push([rawPhone, rawRevenue, `${revenue}`]);
                sumRevenue += revenue;
                continue;
            }
        }
        console.log('laSales :>> ', laSales);
        laSales.push(["Total en euros", `${sumRevenue} €`, `${sumRevenue}`]);
        setLASales(laSales);
    }, [revenueColumn, phoneColumn, leadsPhones, csvContent]);


    const processCSV = (rows) => {
        console.log("going to process CSV");
        if (rows[0].length < 2) {
            setToast({ text: "Echec, votre fichier CSV n'a pas pu être lu.", severity: "error" });
            return;
        }

        // find CSV columns name
        setCSVColumns(rows[0])
        rows.shift();
        console.log('rows :>> ', rows);

        setCSVContent(rows);
        setCSVProcessed(true);
    }

    const customButtonStyle = {
        backgroundColor: color,
        color: 'white',
    };

    const getMainComponent = () => {
        return (
            <Grid container spacing={2} style={{ padding: '10px' }}>
                <Grid item xs={12}>
                    <h3>Compter le chiffre d'affaire généré par Lead Access v2</h3>
                </Grid>

                <Grid container spacing={2} style={{ padding: '10px' }}>
                    <Grid item xs={12}>
                        <CSVReader onUploadAccepted={(results) => processCSV(results.data)}>
                            {({
                                getRootProps,
                                acceptedFile,
                                ProgressBar,
                                getRemoveFileProps,
                            }) => (
                                <>
                                    <div>
                                        {!isCSVProcessed && (
                                            <Button {...getRootProps()} variant="contained" style={customButtonStyle}>
                                                Importer le fichier des ventes
                                            </Button>
                                        )}
                                        <div>
                                            {acceptedFile && acceptedFile.name}
                                        </div>
                                        {/* <button {...getRemoveFileProps()}>
                                            Remove
                                        </button> */}
                                    </div>
                                    <ProgressBar />
                                </>
                            )}
                        </CSVReader>
                    </Grid>

                    {csvColumns !== null ? <Grid key="csv_phone_column" item xs={12}>
                        <InputLabel>Sélectionnez la colonne contennat les numéros de téléphone:</InputLabel>
                        <Select value={phoneColumn} onChange={ev => setPhoneColumn(ev.target.value)}>
                            <MenuItem value="__undefined__">Veuillez choisir</MenuItem>;
                            {csvColumns.map((col, idx) => {
                                return <MenuItem key={col} value={idx}>{col}</MenuItem>;
                            })}
                        </Select>
                    </Grid> : null}

                    {csvColumns !== null ? <Grid key="csv_revenue_column" item xs={12}>
                        <InputLabel>Sélectionnez la colonne contennat le CA:</InputLabel>
                        <Select value={revenueColumn} onChange={ev => setRevenueColumn(ev.target.value)}>
                            <MenuItem value="__undefined__">Veuillez choisir</MenuItem>;
                            {csvColumns.map((col, idx) => {
                                return <MenuItem key={col} value={idx}>{col}</MenuItem>;
                            })}
                        </Select>
                    </Grid> : null}

                    {laSales !== null ?
                        <Grid item xs={12}>
                            <CSVLink data={laSales}>Téléchargez la liste des ventes issues de Lead Access avec leur CA</CSVLink>
                        </Grid>
                        : null}

                    <Grid item xs={12}>
                        <Button onClick={() => window.location.reload()} variant="contained" style={customButtonStyle}>
                            Réinitialiser
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    if (!auth || auth.company_id !== 1) { // todo(3) remove company fixed id
        return null;
    }

    // important avant de remettre ca:
    // update so that we dont select the 250k more b2c if we add them later, how to market those phone numbers……, for now we select all phone numbers
    // _ sauvegarder fichier csv uploaded ????? dans firebase storage ?
    return null;

    return (
        <div style={{ backgroundColor: secondaryColor, minHeight: '100vh' }}>
            <div className="collaborators">
                {toast !== null && <Toast text={toast.text} severity={toast.severity} setToast={setToast} />}
                {confirmation !== null && <ConfirmNotification text={confirmation.text}
                    confirmCallback={confirmation.confirmCallback}
                    setConfirmation={setConfirmation}
                    color={color}
                    showYesButton={confirmation.showYesButton}
                    showNoButton={confirmation.showNoButton}
                    specialPopup={confirmation.specialPopup} />}
                <Header />
                <div className="body">
                    {getMainComponent()}
                </div>
            </div>
        </div>
    );
}

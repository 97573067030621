import { useEffect, useState } from "react";
import { getCompanyUsersMetrics } from "../../api/axios";


export function useUsersUsage({ companyId, duration, job }) {
    const [usersUsageData, setUsersUsageData] = useState(null);

    useEffect(() => {
        if (!companyId || companyId === "__undefined__") {
            return;
        }

        setUsersUsageData(null);
        const cleanedJob = job === "__undefined__" ? null : job;
        getCompanyUsersMetrics(companyId, duration, cleanedJob).then(data => {
            const cleanedData = data.data.map(row => {
                return {
                    fullname: row.fullname,
                    user_type: row.user_type,
                    work_done: parseInt(row.work_done),
                    rdv_taken: parseInt(row.rdv_taken),
                    signings: parseInt(row.signings),
                    rdv_booked: parseInt(row.rdv_booked),
                };
            });
            setUsersUsageData(cleanedData);
        });
    }, [companyId, duration, job]);

    return [
        usersUsageData,
    ];
}

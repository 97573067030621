import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    InputLabel,
    Paper,
    styled,
    Typography
} from "@mui/material";
import React, { useContext, useState } from "react";
import AuthContext from "../../context/AuthProvider";
import { openNewTab } from "../../lib/tools/browser";


/*
    Popup version spéciale uniquement pour Bienviyance
*/


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

const ExternalToolsSelection = ({ handleClose, text }) => {
    const { auth, listsIds, color, secondaryColor } = useContext(AuthContext);

    const [openedUrl, setOpenedUrl] = useState(null);  // null means no Iframe has been selected and displayed

    const customButtonStyle = {
        backgroundColor: color,
        color: 'white',
    };

    if (openedUrl !== null) {
        return (
            <Dialog open={true} maxWidth="95%" maxHeight="90%">
                <Grid item xs={12} container style={{ justifyContent: "space-between" }}>
                    <InputLabel style={{ maxWidth: "80%", whiteSpace: "normal", margin: "10px" }}>
                        {text}
                    </InputLabel>
                    <div>
                        <Button variant="contained" onClick={() => doOpenNewTab(openedUrl)} style={customButtonStyle}>Ouvrir dans un nouvel onglet</Button>
                        <Button variant="contained" onClick={handleClose} style={customButtonStyle}>Fermer</Button>
                    </div>
                </Grid>
                <iframe src={openedUrl} style={{ width: "95vw", height: "80vh" }}></iframe>
            </Dialog>
        );
    }

    function doOpenNewTab(url) {
        openNewTab(url);
        setOpenedUrl(null);
        handleClose();
    }

    function getExternalTools() {
        if (auth.company_nickname === "bien" || auth.company_nickname === "bienstra") {
            return <Grid container spacing={2}>
                <Grid item xs={4} onClick={() => setOpenedUrl("https://bnvce.fr/affaires-nouvelles/")}>
                    <Item>
                        <img src='https://app.lead-access.fr//images/bie/affaires_nouvelles.png' height="30px">
                        </img>
                    </Item>
                </Grid>
                <Grid item xs={4} onClick={() => doOpenNewTab("https://calendar.google.com/calendar/")}>
                    <Item>
                        <img src='https://app.lead-access.fr//images/bie/google_calendar.png' height="30px">
                        </img>
                    </Item>
                </Grid>
                <Grid item xs={4} onClick={() => doOpenNewTab("https://ernest-conseil.fr/login")}>
                    <Item>
                        <img src='https://app.lead-access.fr//images/bie/ernest_conseil.png' height="30px">
                        </img>
                    </Item>
                </Grid>
            </Grid>;
        } else {
            return <Grid item>
                <Typography>
                    Si vous souhaitez ajoutez des liens ici, contactez votre Manager.
                </Typography>
            </Grid>;
        }
    }

    function getProducts() {
        if (auth.company_nickname === "bien" || auth.company_nickname === "bienstra") {
            return <Grid container spacing={2}>
                <Grid item xs={4} onClick={() => doOpenNewTab("https://www.ssogf.generali.fr/user/auth/dologin?tabId=1581612890")}>
                    <Item>
                        <img src='./images/bie/generali.png' height="30px">
                        </img>
                    </Item>
                </Grid>
                <Grid item xs={4} onClick={() => doOpenNewTab("https://espacecourtier.entoria.fr/login")}>
                    <Item>
                        <img src='./images/bie/entoria.svg' height="30px">
                        </img>
                    </Item>
                </Grid>
                <Grid item xs={4} onClick={() => setOpenedUrl("https://www.swisslifeone.fr/index-swisslifeOne.html#/accueil")}>
                    <Item>
                        <img src='./images/bie/swisslife.png' height="30px">
                        </img>
                    </Item>
                </Grid>
                <Grid item xs={4} onClick={() => doOpenNewTab("https://extranet.neoliane.fr/connection?lostpage=dashboard")}>
                    <Item>
                        <img src='./images/bie/neoliane.png' height="30px">
                        </img>
                    </Item>
                </Grid>
                <Grid item xs={4} onClick={() => doOpenNewTab("https://pro.alptis.org/auth/realms/alptis-distribution/protocol/openid-connect/auth?client_id=espace-distributeur&redirect_uri=https%3A%2F%2Fpro.alptis.org%2F&state=4e2ca98f-58db-40ff-a834-df6636312639&response_mode=query&response_type=code&scope=openid&nonce=002c1ddf-a031-4016-afa0-b1c40658a198")}>
                    <Item>
                        <img src='./images/bie/alptis.png' height="30px">
                        </img>
                    </Item>
                </Grid>
                <Grid item xs={4} onClick={() => doOpenNewTab("https://soizic.zenioo.com/ZN_Th/Login")}>
                    <Item>
                        <img src='./images/bie/zenioo.png' height="30px">
                        </img>
                    </Item>
                </Grid>
            </Grid>;
        } else {
            return <Grid item>
                <Typography>
                    Si vous souhaitez ajoutez des liens ici, contactez votre Manager.
                </Typography>
            </Grid>;
        }
    }

    if (!auth || !color) {
        return null;
    }

    return (
        <Dialog open={true} maxWidth="80%" maxHeight="80%">
            <DialogActions>
                <Button variant="contained" onClick={handleClose} style={customButtonStyle}>Fermer</Button>
            </DialogActions>
            <DialogContent>
                <Grid item xs={4}>
                    <h2 style={{ color: color }}>Outils internes</h2>
                    <br />
                </Grid>
                {getExternalTools()}
                <br />

                <Grid item xs={4}>
                    <h2 style={{ color: color }}>Produits</h2>
                    <br />
                </Grid>
                {getProducts()}
                <br />

                <Grid item xs={4}>
                    <h2 style={{ color: color }}>Simulations</h2>
                    <br />
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={4} onClick={() => doOpenNewTab("https://www.boursorama.com/patrimoine/impots/simulateur/impot-revenu")}>
                        <Item>
                            <Typography variant="h6">
                                Impots sur le revenu
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={4} onClick={() => setOpenedUrl("https://www.meilleurtaux.com/credit-immobilier/simulation-de-pret-immobilier/calcul-des-mensualites.html")}>
                        <Item>
                            <Typography variant="h6">
                                Crédit immobilier
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={4} onClick={() => doOpenNewTab("https://www.boursorama.com/patrimoine/retraite/simulateur-plan-epargne-retraite-per")}>
                        <Item>
                            <Typography variant="h6">
                                PER
                            </Typography>
                        </Item>
                    </Grid>
                </Grid>
                <br />
            </DialogContent>
        </Dialog>
    );
};

export default ExternalToolsSelection;

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { LeadStatus } from "../../../context/enums";
import { date2frenchString, getFrenchDayName } from "../../../lib/tools/datetime";
import { sumArray } from "../../../lib/tools/numbers";


export default function TableDaysUsage({ daysUsageData }) {
    if (!daysUsageData) {
        return null;
    }

    const sumWorkDone = sumArray(daysUsageData.map((row) => row.work_done));
    const sumRdvBooked = sumArray(daysUsageData.map((row) => row.rdv_booked));
    const sumRdvTaken = sumArray(daysUsageData.map((row) => row.rdv_taken));
    const sumSignings = sumArray(daysUsageData.map((row) => row.signings));

    const percentageRdvBooked = (sumRdvBooked / sumWorkDone * 100).toFixed(1);
    const percentageRdvTaken = (sumRdvTaken / sumWorkDone * 100).toFixed(1);
    const percentageSigning = (sumSignings / sumWorkDone * 100).toFixed(1);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell align="right">Leads travaillés</TableCell>
                        <TableCell align="right">{LeadStatus["RDV_PRIS"]}</TableCell>
                        <TableCell align="right">{LeadStatus["RDV_HAPPENED"]}</TableCell>
                        <TableCell align="right">{LeadStatus["SIGNE"]}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {daysUsageData.map((row) => {
                        const dayStr = getFrenchDayName(new Date(row.day)) + " " + date2frenchString(new Date(row.day));

                        return (
                            <TableRow key={row.day} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">{dayStr}</TableCell>
                                <TableCell align="right">{row.work_done}</TableCell>
                                <TableCell align="right">{row.rdv_booked}</TableCell>
                                <TableCell align="right">{row.rdv_taken}</TableCell>
                                <TableCell align="right">{row.signings}</TableCell>
                            </TableRow>
                        );
                    }).concat(sumWorkDone ? [
                        <TableRow key={"__sum__"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">Total</TableCell>
                            <TableCell align="right">{sumWorkDone}</TableCell>
                            <TableCell align="right">{`${sumRdvBooked} (${percentageRdvBooked}%)`}</TableCell>
                            <TableCell align="right">{`${sumRdvTaken} (${percentageRdvTaken}%)`}</TableCell>
                            <TableCell align="right">{`${sumSignings} (${percentageSigning}%)`}</TableCell>
                        </TableRow>
                    ] : [])}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

import React from "react";
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { date2frenchString, getFrenchDayName } from "../../../lib/tools/datetime";
import { findNextMultiple, getArrayMaxValue } from "../../../lib/tools/numbers";

export default function GraphUsersWork({ daysUsageData }) {
    if (!daysUsageData) {
        return null;
    }

    const maxYAxis = findNextMultiple(Math.round(getArrayMaxValue(daysUsageData.map(row => row.work_done)) * 1.2 + 1), 4);

    function getDisplayDate(day) {
        return getFrenchDayName(new Date(day)) + " " + date2frenchString(new Date(day));
    }

    return (
        <div style={{ width: '100%', height: 460 }}>
            <ResponsiveContainer>
                <LineChart
                    data={daysUsageData.map(row => {
                        return { ...row, "Nombre de leads": row.work_done, displayDate: getDisplayDate(row.day) }
                    })}
                    margin={{ top: 40, right: 60, left: 0, bottom: 40 }}>
                    <XAxis dataKey="displayDate" angle={45} height={150} textAnchor="start" />
                    <YAxis domain={[0, maxYAxis]} />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Nombre de leads" stroke="#8884d8" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}
